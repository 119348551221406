<template>
  <div class=" h-100-vh ">
    <OrderComponent :orderId="orderId" v-if="orderId != null"/>
  </div>
</template>
<script>
  import OrderComponent from '@/components/order/order-component';

  import {
    mapState,
    mapMutations,
    mapActions,
    mapGetters,
  } from "vuex";

  export default {
    components: {
      OrderComponent,
    },
    data() {
      return ({
        orderId: null,
      });
    },
    watch: {},
    computed: {
      ...mapState('order', ['modalCancelOrder']),
    },
    methods: {
      // ...mapActions('order', ['fnApiGetOrder']),
      // ...mapMutations('order', ['setModalCancelOrder']),
    },
    mounted() {
      this.orderId = this.$route.params.id;
    }
  }
</script>